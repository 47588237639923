import React from 'react';
import * as style from './AboutUs.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import {Link, graphql, StaticQuery} from 'gatsby';

const AboutUs = () => {
	 return(

			 <StaticQuery query={graphql`{
	         allWpContent(filter: { title: {eq: "Homepage"}}){
	           edges{
	             node{
	               homepage{
	                 omOs
									 omImage{
										 gatsbyImage(width: 400, placeholder: BLURRED)
									 }
	               }
	             }
	           }
	         }
	     }`} render={props => (
				 <div className={style.wrapper}>
				 {
					 props.allWpContent.edges.map(content => (
						 <div className={style.content}>
							 <div className={style.info}>
								 <h2 className={style.heading}>Om Os</h2>
								 <p className={style.text}>
									 {content.node.homepage.omOs}
								 </p>
								 <Link to='/om' className={style.button}>Læs mere</Link>
							 </div>
							 <GatsbyImage image={content.node.homepage.omImage.gatsbyImage} className={style.image} alt=''/>
	 					 </div>
					 ))}
					</div>
			 )} />




	 )
}
export default AboutUs;
