import React from 'react';
import * as styles from './Hook.module.scss';
import {Link} from 'gatsby';

const Hook = () => {
  return (
    <div className={styles.hookWrapper}>
      <div className={styles.textWrapper}>
        <h1 className={styles.heading}>Velkommen til Paulsen Regnskab</h1>
        <h3 className={styles.underheading}>
          Hos Paulsen Regnskab tilbyder vi forskellige serviceydelser i form af: bogføring, regnskaber, opgørelse af moms & indberetning,
          lønadministration, skat og selskabsstiftelse.
        </h3>
        <div>
          <Link to='/kontakt'><button className={styles.actionButton}>Kontakt os</button></Link>
          <Link to='/om'><button className={styles.secondaryButton}>Læs mere</button></Link>
        </div>
      </div>

    </div>
  )
}

export default Hook;
