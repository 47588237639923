// extracted by mini-css-extract-plugin
export var content = "Tjenester-module--content--9be09";
export var heading = "Tjenester-module--heading--f5bec";
export var headingWrapper = "Tjenester-module--heading-wrapper--12206";
export var image = "Tjenester-module--image--f5393";
export var info = "Tjenester-module--info--b2997";
export var infoText = "Tjenester-module--info-text--0388d";
export var item = "Tjenester-module--item--87979";
export var itemTitle = "Tjenester-module--item-title--3ecba";
export var link = "Tjenester-module--link--d445e";
export var services = "Tjenester-module--services--8c6ae";
export var text = "Tjenester-module--text--062cc";
export var wrapper = "Tjenester-module--wrapper--bd06c";