import React from 'react';
import Layout from '../components/Layout/Layout';
import Hook from '../components/Hook/Hook';
import Tjenester from '../components/Tjenester/Tjenester';
import AboutUs from '../components/AboutUs/AboutUs';

import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const IndexPage = () => {



  return (
    <Layout>
      <Hook />
      <Tjenester />
      <AboutUs />
    </Layout>
  )
}

export default IndexPage;
