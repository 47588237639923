import React from 'react';
import * as style from './Tjenester.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import {StaticQuery, Link, graphql} from 'gatsby';
const Tjenester = () => {
	 return(
		 <div className={style.wrapper}>
						<StaticQuery query={graphql`
							{
								allWpYdelse {
					       edges {
					         node {
					           id
					           title
					           excerpt
					           slug
					           featuredImage {
					             node {
					               sourceUrl
					               gatsbyImage(width: 600, placeholder: BLURRED)
					             }
					           }
					         }
					       }
					     }
							 allWpContent(filter: { title: {eq: "Homepage"}}){
			           edges{
			             node{
			               homepage{
											 ydelser
			               }
			             }
			           }
			         }
							}
						`} render={props => (
							<div className={style.content}>
								<div className={style.headingWrapper}>
									<h2 className={style.heading}>Ydelser</h2>
									<p className={style.text}>
										{props.allWpContent.edges.map(content => (
											content.node.homepage.ydelser
										))}
									</p>
								</div>
							<div className={style.services}>
							{
								props.allWpYdelse.edges.map(ydelse => (
									<Link to={`/ydelser/${ydelse.node.slug}`} className={style.item}>
											<GatsbyImage image={ydelse.node.featuredImage.node.gatsbyImage} className={style.image} alt=""/>
											<div className={style.info}>
												<div className={style.infoText}>
													<div className={style.itemTitle}>
														{ydelse.node.title}
													</div>
													<div dangerouslySetInnerHTML={{__html: ydelse.node.excerpt}} />

												</div>
											</div>
									</Link>
								))
							}
							</div>
						</div>
						)}

						/>

		 </div>
	 )
}
export default Tjenester;
